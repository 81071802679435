<template>
  <div class="app-contianer">
    <div class="phone-title">
      <div class="phone-img">
        <img src="../../../assets/user/loginFood.png" />
      </div>
      <div class="phone-text">
        <span>Hello，欢迎来到试吃团~</span>
      </div>
    </div>
    <div class="phone-button" @click="gotoupdate">更新信息</div>
  </div>
</template>

<script>

export default {
  name: 'undateUser',
  methods: {
    gotoupdate() {
      var appid = localStorage.getItem('appid')
      var url =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=https://wxmp.youfanshenghuo.com/&response_type=code&scope=snsapi_userinfo&state=123&component_appid=wx30a1ec0b9bfbcf9b#wechat_redirect'
      window.location.href = url
      this.$toast('更新成功,请返回')
    },
  },
}
</script>

<style scoped lang="less">
.app-contianer {
  padding-bottom: 0;
  background: none;
  .phone-title {
    width: 100%;
    height: auto;
    padding-bottom: 4vh;
    padding-top: 4vh;
    text-align: center;
    .phone-img {
      width: 100%;
      height: auto;
      img {
        width: 20.7vw;
        height: 8.3vh;
      }
    }
    .phone-text {
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 4vw;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-top: 2vh;
    }
  }
  .phone-button {
    width: 95%;
    height: 6vh;
    margin-left: 2.5%;
    margin-top: 15vh;
    background: linear-gradient(-90deg, #9169f6 0%, #6497ff 100%);
    border-radius: 3vh;
    text-align: center;
    line-height: 6vh;
    font-size: 3.5vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
